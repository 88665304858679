@font-face {
  font-family: "NoirPro";
  src:
    local("NoirPro-Medium"),
    url("./assets/fonts/NoirPro/NoirPro-Regular.woff2") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "NoirPro-Medium";
  src:
    local("NoirPro-Medium"),
    url("./assets/fonts/NoirPro/NoirPro-Medium.woff2") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Open Sans";
  src:
    local("OpenSans-Regular"),
    url("./assets/fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Open Sans Medium";
  src:
    local("OpenSans-Medium"),
    url("./assets/fonts/OpenSans/OpenSans-Medium.ttf") format("truetype");
  font-weight: 400;
}
